import { twJoin } from "tailwind-merge";
import styles from "./ChatBackdrop.module.css";
import type { Component } from "solid-js";
import { useWire } from "@core/wire";

export const ChatBackdrop: Component<{ height: number }> = (props) => {
  const wire = useWire();
  return (
    <div aria-hidden class="fixed bottom-0 inset-x-0 pointer-events-none -z-10">
      <div class={twJoin("w-full mx-auto", styles["gradient-blur"])} style={{ height: `${props.height + 80}px` }} />
    </div>
  );
};
