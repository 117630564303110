import type { ParentProps } from "solid-js";
import { classNames } from "@core/lib/classNames";
import styles from "./FloatingPromptBarContainer.module.css";
import { useWire } from "@core/wire";
import { ChatBackdrop } from "@core/domains/chat/prompt/components/ChatBackdrop";

interface Props extends ParentProps {
  id?: string;
  modifier?: "fixed" | "left";
  class?: string;
}

export const FloatingPromptBarContainer = (props: Props) => {
  const wire = useWire();
  return (
    <div
      id={props.id}
      class={classNames(
        styles["floating-prompt-bar-container"],
        props.modifier === "fixed" && styles["floating-prompt-bar-container--fixed"],
        props.modifier === "left" && styles["floating-prompt-bar-container--left"],
        wire.isExtension() && styles["floating-prompt-bar-container--extension"],
        props.class,
      )}
    >
      <ChatBackdrop height={60} />

      {props.children}
    </div>
  );
};
